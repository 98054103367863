import React from 'react'
import { Router } from '@reach/router'
import RAC from '../../../templates/RAC'
import DynamicNotFoundPage from '../../dynamicNotFound'

export default () => (
  <Router>
    <RAC path="/ja/energy-natural-resources/*" />
    <RAC path="/zh-cn/energy-natural-resources/*" />
    <RAC path="/zh-tw/energy-natural-resources/*" />
    <RAC path="/pl/energy-natural-resources/*" />
    <RAC path="/ru/energy-natural-resources/*" />
    <RAC path="/it/energy-natural-resources/*" />
    <RAC path="/de/energy-natural-resources/*" />
    <RAC path="/es/energy-natural-resources/*" />
    <RAC path="/fr/energy-natural-resources/*" />
    <RAC path="/ar/energy-natural-resources/*" />
    <RAC path="/id/energy-natural-resources/*" />
    <RAC path="/pt/energy-natural-resources/*" />
    <RAC path="/ko/energy-natural-resources/*" />
    <DynamicNotFoundPage default />
  </Router>
)
